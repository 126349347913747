import React from "react"
import {graphql} from "gatsby"
import {Col, Container, Row} from "react-bootstrap";
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {ResponseForm} from "gatsby-theme-nieuwbouw/src/components/response/Form";
import {Header} from "../components/Header";
import DNA from "../components/DNA";
import Realisatie from "../components/Realisatie";
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    const {objectcode, afdelingscode, referral} = data.site.siteMetadata
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <Header>
                <StaticImage src={'../img/sfeer/Omgevig-Zeewolde.jpg'} aspectRatio={3} alt={'header'}/>
            </Header>
            <Container
                fluid={'lg'}
            >
                <Row>
                    <Col md={7} className={'my-5'}>
                        <ResponseForm objectcode={objectcode} afdelingscode={afdelingscode} referral={referral} />
                    </Col>
                    <Col md={5}>
                        <div className={"px-5 my-5"}>
                            <div className={"mb-5"}>
                                <DNA />
                            </div>
                            <Realisatie />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
    site: {
        siteMetadata: {
            objectcode: string
            afdelingscode: string
            referral: string
        }
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "inschrijven"}) {
            childMarkdownRemark {
                ...Page
            }
        }
        site {
            siteMetadata {
                objectcode
                afdelingscode
                referral
            }
        }
    }
`

